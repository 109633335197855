import React from 'react'
import Footer from './footer'
import Header from './header'
import '../styles/global.css'



// eslint-disable-next-line react/prop-types
const Layout = ({children}) => {
    return (
        <div className="bg-gray-200 font-serif">
            <Header />
            <main>{children}</main>
            <Footer />
        </div>
    )
}

export default Layout