import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faFacebookF, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'gatsby'




const Header = () => {
    const [menuOpen , SetMenuOpen] = useState(false)
    return (
        <>
            <div className=" bg-gradient-to-r from-orange-light to-orange-dark flex justify-around items-center h-28">
                <div className="text-white">
                    <Link to='/'>
                        <div className=" text-6xl">rozJob</div>
                        <div className=" text-3xl ml-3">Her Roz Job</div>
                    </Link>
                </div>
             
             
                {/* <div className="hidden lg:flex justify-between w-60 text-white">
                    <div>JOBSEEKERS</div>
                    <div>EMPLOYERS</div>
                </div> */}


                <div className="hidden lg:flex justify-between">
                    <button className="bg-blue-700 text-white px-4 py-1 m-2 rounded">Newsletter Sign up</button>
                    <button className="bg-white text-blue-700 px-4 py-1 m-2">Login</button>                
                </div>


                <div onClick={() => SetMenuOpen(!menuOpen)} className="lg:hidden cursor-pointer ">
                    <div className="border border-black w-8 m-2"></div>
                    <div className="border border-black w-8 m-2"></div>
                    <div className="border border-black w-8 m-2"></div>
                </div>
                
            </div>

            <div className="bg-gray-700 text-white lg:flex justify-center flex-row hidden">
                <div className="flex flex-row justify-around h-10">
                    <button><Link to='/all-jobs/' className="mx-2">All Jobs</Link></button>
                    <button className="mx-2">About Membership</button>
                    <button className="mx-2">Apartment rentals</button>
                    <button className="mx-2">Business Directory</button>
                </div>

                <div className="flex items-center text-xl mx-40">
                    <FontAwesomeIcon className="mx-1" icon={faFacebookF} />
                    <FontAwesomeIcon className="mx-1" icon={faTwitter} />
                    <FontAwesomeIcon className="mx-1" icon={faInstagram} />
                    <FontAwesomeIcon className="mx-1" icon={faLinkedin} />

                </div>

                <div onClick={() => SetMenuOpen(!menuOpen)}  className="flex items-center text-xl bg-red-600 px-4 cursor-pointer">
                    <div>Menu</div>
                    <div className="">
                    <div className="border border-white w-5 m-1"></div>
                    <div className="border border-white w-5 m-1"></div>
                    <div className="border border-white w-5 m-1"></div>
                </div>

                </div>
            </div>

            {menuOpen &&  <div className="flex justify-center">
                <div className=" flex justify-around flex-wrap bg-gray-800 text-white max-w-7xl">
                  
                  
                    <div  className="p-4">
                        <p className="w-72 P-8 text-orange">Topics</p>
                        <hr />
                        <div className="m-8 flex justify-between flex-wrap w-80">
                            <Link className="w-32">Coronavirus</Link>
                            <Link className="w-32">Environment</Link>
                            <Link className="w-32">Word of the Day</Link>
                            <Link className="w-32">Travel</Link>
                            <Link className="w-32">Brexit</Link>
                            <Link className="w-32">Berlin</Link>
                            <Link className="w-32">Bavaria</Link>
                            <Link className="w-32">More ...</Link>
                        </div>
                    </div>





                    <div className="p-4">
                        <p className="w-72 P-8 text-orange">Topics</p>
                        <hr />
                        <div className="m-8 flex flex-wrap w-80 justify-between">
                            <Link className="w-32">Coronavirus</Link>
                            <Link className="w-32">Environment</Link>
                            <Link className="w-32">Word of the Day</Link>
                            <Link className="w-32">Travel</Link>
                            <Link className="w-32">Brexit</Link>
                            <Link className="w-32">Berlin</Link>
                            <Link className="w-32">Bavaria</Link>
                            <Link className="w-32">More ...</Link>
                        </div>
                    </div>



                    <div className="p-4">
                        <p className="w-72 P-8 text-orange">Topics</p>
                        <hr />
                        <div className="m-8 flex flex-wrap w-80 justify-between">
                            <Link className="w-32">Coronavirus</Link>
                            <Link className="w-32">Environment</Link>
                            <Link className="w-32">Word of the Day</Link>
                            <Link className="w-32">Travel</Link>
                            <Link className="w-32">Brexit</Link>
                            <Link className="w-32">Berlin</Link>
                            <Link className="w-32">Bavaria</Link>
                            <Link className="w-32">More ...</Link>
                        </div>
                    </div>



                    <div className="p-4">
                        <p className="w-72 P-8 text-orange">Topics</p>
                        <hr />
                        <div className="m-8 flex flex-wrap w-80 justify-between">
                            <Link className="w-32">Coronavirus</Link>
                            <Link className="w-32">Environment</Link>
                            <Link className="w-32">Word of the Day</Link>
                            <Link className="w-32">Travel</Link>
                            <Link className="w-32">Brexit</Link>
                            <Link className="w-32">Berlin</Link>
                            <Link className="w-32">Bavaria</Link>
                            <Link className="w-32">More ...</Link>
                        </div>
                    </div>



                    <div className="p-4">
                        <p className="w-72 P-8 text-orange">Topics</p>
                        <hr />
                        <div className="m-8 flex flex-wrap w-80 justify-between">
                            <Link className="w-32">Coronavirus</Link>
                            <Link className="w-32">Environment</Link>
                            <Link className="w-32">Word of the Day</Link>
                            <Link className="w-32">Travel</Link>
                            <Link className="w-32">Brexit</Link>
                            <Link className="w-32">Berlin</Link>
                            <Link className="w-32">Bavaria</Link>
                            <Link className="w-32">More ...</Link>
                        </div>
                    </div>



                    <div className="p-4">
                        <p className="w-72 P-8 text-orange">Topics</p>
                        <hr />
                        <div className="m-8 flex flex-wrap w-80 justify-between">
                            <Link className="w-32">Coronavirus</Link>
                            <Link className="w-32">Environment</Link>
                            <Link className="w-32">Word of the Day</Link>
                            <Link className="w-32">Travel</Link>
                            <Link className="w-32">Brexit</Link>
                            <Link className="w-32">Berlin</Link>
                            <Link className="w-32">Bavaria</Link>
                            <Link className="w-32">More ...</Link>
                        </div>
                    </div>



                </div>



            </div>
            }


            <div className="bg-header-bg h-72 text-white flex flex-col justify-center items-center">
                <p className="3-8 text-3xl">ALL JOBS IN PAKISTAN</p>
                <p className="text-3xl m-3">Find one you love.</p>
                <button className="bg-orange rounded-xl px-10 py-2 ">Search</button>
            </div>


            
        </>
    )
}

export default Header