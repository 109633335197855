import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faRss } from '@fortawesome/free-solid-svg-icons'


const Footer = () => {
    return (

        <div className="bg-black text-white flex justify-center w-full p-6">
            <div className="flex justify-between flex-wrap border-white border max-w-7xl  items-center">

                <p className="mx-12  my-3  md:my-12">
                    752, Allama Iqbal Town,  <br /> Shaire Mashriq Road, <br /> Lahore
                </p>

                <ul className="mx-12 my-3  md:my-12">
                    <li>Contact Us</li>
                    <li>Our Journalist</li>
                </ul>

                <ul className="mx-12 my-3  md:my-12">
                    <li>Advertise with us</li>
                    <li>Post a job ad</li>
                </ul>
                
                <ul className="mx-12 my-3  md:my-12">
                    <li>Terms of Use</li>
                    <li>Privacy Policy</li>
                    <li>Privacy Consent</li>
                </ul>
            
                <div className="mx-12 my-3  md:my-12 flex items-center text-2xl">
                    <FontAwesomeIcon className="mx-1" icon={faFacebookF} />
                    <FontAwesomeIcon className="mx-1" icon={faTwitter} />
                    <FontAwesomeIcon className="mx-1" icon={faLinkedin} />
                    <FontAwesomeIcon className="mx-1" icon={faRss} />
                </div>                
            </div>
        </div>
    )
}

export default Footer
